import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

const importAll = (r, cache) => r.keys().forEach(
  (key) => cache[key] = r(key)
)
const imageFiles = {}
importAll(require.context(`../content/explorer-contents/`, true, /\.(png|gif|jpg)$/), imageFiles)
importAll(require.context(`../content/trailblazer-contents/`, true, /\.(png|gif|jpg)$/), imageFiles)
const transformImageUri = slug => uri => {
  const imgPath = `./${slug}/${uri.substring(2)}`
  const importedImg = imageFiles[imgPath]
  return importedImg ? importedImg.default : ''
}

const Divider = ({ text, margins = {} }) => <div
  className={`relative ${margins.b ? 'mb-5' : ''} ${margins.t ? 'mt-10' : ''}`}>
  <div className="absolute inset-0 flex items-center" aria-hidden="true">
    <div className="w-full border-t border-gray-300"></div>
  </div>
  <div className="relative flex justify-center">
        <span className="px-3 bg-white text-lg font-medium text-gray-900">
            {text}
        </span>
  </div>
</div>

const BlogPost = ({ pageContext: { content } }) => {
  const bannerImg = 'http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png'

  const attributes = {
    title: '2022 - Advent Calendar',
    description: 'WaxCarvers Advent Calendar'
  }
  return <Layout>
    <Helmet>
      <title>{attributes.title} ¦ Wax Carvers</title>
      <meta name="description" content={attributes.description} />
      <meta property="og:title" content={`${attributes.title} | Wax Carvers`} />
      <meta property="og:image" content={bannerImg} />
      <meta property="og:url" content={`https://www.waxcarvers.com/advent-calendar`} />
      <meta property="og:description" content={attributes.description} />

      <meta itemProp="name" content={`${attributes.title} | Wax Carvers`} />
      <meta itemProp="description" content={attributes.description} />

      <meta property="twitter:title" content={`${attributes.title} | Wax Carvers`} />
      <meta property="twitter:description" content={attributes.description} />
      <meta property="twitter:card" content={bannerImg} />
      <meta property="twitter:image:src" content={bannerImg} />
    </Helmet>
    <div className="relative overflow-hidden">
      <div className="relative pt-2 pb-12 lg:pb-20">

        <div className="mt-5 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-8 lg:mt-10">
          {content.map(it => {
            return <div key={it.slug}>
              <div className="prose prose-sm sm:prose lg:prose-lg mx-auto mt-20">
                <ReactMarkdown
                  transformImageUri={transformImageUri(it.slug)}
                  allowDangerousHtml
                  children={it.body} />
              </div>
              <Divider margins={{ t: true, b: true }} />
            </div>
          })}
        </div>
      </div>
    </div>
  </Layout>
}

export default BlogPost
